
import Dashboard from "../pages/Dashboard";

function Main() {

  return (
    <>

      <Dashboard />

    </>
  )
}

export default Main;